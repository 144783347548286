.loading-view {
  display: flex;
  flex-direction: column;
  & .am-activity-indicator-spinner-lg {
    width: 0.36rem;
    height: 0.36rem;
    margin-bottom: 0.1rem;
  }
}
.inline-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.32rem 0;

  & .title {
    color: #1f1f1f;
    font-size: 0.28rem;
  }
  & .click-hint {
    margin-left: 0.24rem;
  }
  & .inline-extra {
    font-size: 0.28rem;
    color: #666;
  }
  & .inline-right {
    color: #8B8F97;
    font-size: 0.24rem;
    align-items: center;
    display: flex;
		flex: 1;
		& .price {
			font-size: 0.28rem;
			color: #FE5500;
		}
    & .am-icon-lg {
      width: 0.46rem;
      height: 0.46rem;
    }
  }
  & .item-input {
    flex: 1;
    font-size: 0.3rem;
    color: #1f1f1f;
    text-overflow: ellipsis;
  }
  & .item-input::placeholder {
    color: #ccc;
  }
}
.inline-edit {
  height: 1.16rem;
  justify-content: flex-start;
}
.tab-item {
  height: 0.56rem;
  border-radius: 0.28rem;
  background-color: #fe5500;
  color: #fff;
  padding: 0 0.24rem;
  font-size: 0.26rem;
  display: flex;
  align-items: center;
  margin-right: 0.32rem;
}
.tab-item-normal {
  background-color: #fff;
  border: 2px solid #b0b4ba;
  color: #666666;
}
/* ProductItem -start- */
.product-item {
	background: #fff;
	margin: 0.2rem 0.2rem 0;
	padding: 0 0.2rem;
	border-radius: 0.08rem;
  & .merchant-wrap {
    display: flex;
    padding: 0.2rem 0;
    font-size: 0.26rem;
    color: #1f1f1f;
    align-items: center;
  }
  & .merchant-logo {
    width: 0.48rem;
    height: 0.48rem;
    border-radius: 0.08rem;
    margin-right: 0.2rem;
  }
  & .product-wrap {
    display: flex;
    flex-direction: row;
    padding: 0.32rem 0;
    border-bottom: 1px solid #ececec;
  }

  & .product-img {
    width: 1.44rem;
    height: 1.44rem;
    border-radius: 0.12rem;
  }
  & .product-right {
    display: flex;
    flex-direction: column;
    margin-left: 0.32rem;
    flex: 1;
		justify-content: space-between;
		& .price-int {
      font-size: 0.26rem;
      color: #666D92;
    }
    & .price-decimal {
      font-size: 0.28rem;
      color: #ff5a5f;
    }
    & .product-num {
      font-size: 0.24rem;
      color: #B0B4BA;
    }
    & .name-warp {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  & .product-name {
    font-size: 0.28rem;
    color: #1f1f1f;
    line-height: 0.42rem;
    max-width: 4rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .product-sku {
    align-self: flex-start;
    font-size: 0.24rem;
    color: #999;
  }
  & .right-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .price-int {
      font-size: 0.26rem;
      color: #666D92;
    }
    & .price-decimal {
      font-size: 0.28rem;
      color: #ff5a5f;
    }
  }
  & .ship-fee {
    margin-left: 0.2rem;
  }
  & .invoice-entry {
    color: #999;
  }
}
/* ProductItem -end- */
/* RegisterModal -start- */
.register-modal {
  border-radius: 0.1rem;
  background-color: #fff;
  padding: 0.5rem 0.3rem 0 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .close-icon-wrap {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.9rem;
    height: 0.9rem;
    padding: 0.3rem;
    & .close-icon {
      display: inline-block;
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  & .phone-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.3rem;
    height: 0.8rem;
    border-bottom: 1px solid #e4e4e4;
    margin-top: 0.3rem;

    & input {
      color: #333333;
      font-size: ;
    }
    & span {
      font-size: 0.26rem;
      color: #cccccc;
    }
  }
  & .submit-btn {
    margin-top: 0.6rem;
  }
  & .np-brand {
    width: 1.05rem;
    height: 1.05rem;
    border-radius: 0.2rem;
  }
  & .phone-err {
    align-self: flex-start;
    height: 0.2rem;
    margin-top: 0.1rem;
    color: #ffc15c;
    font-size: 0.26rem;
  }
}
/* RegisterModal -end- */
/* ActionButton -start-*/
.action-btn {
  width: 5.1rem;
  height: 0.88rem;
  border-radius: 0.44rem;
  background-color: #fe5500;
  color: #fff;
  font-size: 0.32rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-btn-reverse {
  border: 2px solid #fe5500;
  background-color: #fff;
  color: #fe5500;
}
/* ActionButton -end-*/
