#root{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
html:not([data-scale]) .am-tabs-default-bar-top .am-tabs-default-bar-tab::after{
      height: 0.04rem;
    background-color:#E8E8E8;
}
.ThreeReduce{
  & .top-infor{
    width: 100%;
    padding-top: 1rem;
  }
  & .am-tabs-tab-bar-wrap{
    height: 0.96rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 100;

  }
  & .am-tabs-default-bar-top .am-tabs-default-bar-tab{
    font-size:0.28rem;
    font-family:PingFangSC-Regular,PingFangSC;
    font-weight:400;
    color:rgba(153,153,153,1);
    line-height:0.4rem;
    height: 100%;
    /*width: 16.67% !important;*/
  }
  & .am-tabs-default-bar-underline{
    border: 0.02rem #3CD694 solid;
    bottom: 0.12rem;
  }
  & .am-tabs-default-bar-top .am-tabs-default-bar-tab::after{
    height: 0;
  }
  & .content-item{
    padding-top: 0.38rem;
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    font-size: 0.32rem;
  }
  & .content-item .part{
    color: #666666;
    margin-bottom: 0.55rem;
    line-height: 0.52rem;
    text-align: justify;
  }
  & .content-item .part .tip-top{
    color: #1F1F1F;
    line-height: 0.52rem;
    height: 0.52rem;
    margin-bottom: 0.12rem;
  }
  & .content-item .part .section{
    text-indent: 2em;
  }
  & .content-item .part img {
    margin-top: 0.28rem;
    margin-bottom: 0.28rem;
    border-radius: 0.2rem;
    width: 100%;
  }
  & .what-three{
    position: fixed;
    top: 30%;
    right: 0;
    width: 0.6rem;
    height: 0.6rem;
  }
  & .what-three img{
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50%;
  }
  & .content-info-pop{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
  & .content-info-pop .bg{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
    background-color: #222222;
    opacity: 0.4;

  }
  & .content-info-pop .part{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 102;
    background-color: #ffffff;
    padding-top: 0.4rem;
    padding-left: 0.32rem;
    padding-right: 0.32rem;
    padding-bottom: 0.4rem;

  }
}

