.tkHospital{
	flex:1;
	& .hdWrap{
		background:'#ffffff';
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		color: #000;
		& .hdCotentWrap {
			display: flex;
			width: 100%;
			height: 1.06rem;
			padding: 0 0.16rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			& .name {
				font-size: 0.36rem;
				position: absolute;
				left: 0;
				right: 0;
				z-index: -1;
				width: 100%;
				height: 1.06rem;
				line-height: 1.06rem;
				text-align: center;
			}
		
			& span{
				font-size: 0.34rem;
				width: 0.6rem;
				height: 0.6rem;
				text-align: center;
				line-height: 0.6rem;
				&.iconservice{
					margin-right: 0.3rem;
					font-size: 0.36rem;
				}
				&.iconguaranteeslip{
					font-size: 0.36rem;
				}
			}
	
		
			
		}
	}
}