.DownLoadAppPage{
  & .cont{
    text-align: center;
    margin-top: 40%;

  }
  & .cont .logo-img{
    display: inline-block;
    width: 30%;
    border-radius: 0.3rem;
  }

}
.DownLoadAppGuidance{
  & .cont{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  & .cont .bg-img{
    display: inline-block;
    width: 100%;

  }
  & .cont .part-down{
    position: relative;
    width: 100%;
  }
  & .cont .part-down .down-btn{
    position: absolute;

    top: 0;
    width: 50%;
    left: 25%;
    right: 25%;
  }
  & .cont .part-down .down-btn img{
    display: inline-block;
    width: 100%;
    
  }
  & .cont .part-down .bg-img{
      display: inline-block;
      width: 100%;
  }

}

.QrcodeDownLoad{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.QrcodeDownLoad .cont{


}

.QrcodeDownLoad .cont .logo-img{
  display: inline-block;
  width: 3.8rem;
  height: 3.8rem;


}