.message-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .msg-img {
    width: 5.26rem;
    height: 5rem;
  }
  & .title {
    font-size: 0.36rem;
    margin-bottom: 0.2rem;
  }
  & .content {
    font-size: 0.3rem;
    color: #999999;
  }
  & .actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.58rem;
    border-width: 1px;
    border: 1px solid #cdcdcd;
    border-radius: 0.1rem;
    margin-top: 0.4rem;
  }
  & .actionTitle {
    color: #ccc;
    font-size: 0.32rem;
    padding: 0 0.38rem;
  }
}
