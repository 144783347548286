.insulic-wrap {
	padding: 1rem 0.3rem 2rem;
	display: flex;
	flex-direction: column;

	& .nav-padding {
		height: 1.5rem
	}
	
	& .licenceTitle {
		color: #333;
		font-size: 0.34rem;
		line-height: 0.48rem;
	}

	& .licenceSection {
		color: #000;
		font-size: 0.3rem;
		line-height: 0.42rem;
		margin-top: 0.5rem;
	}


	& .licenceContent {
		color: #000;
		font-size: 0.28rem;
		line-height: 0.48rem;
		text-align: justify;
		margin-top: 0.1rem;
	}

	& .upper {
		font-size: 0.07rem;
		vertical-align: super;
		
	}

	& .lower {
		font-size: 0.07rem;
		vertical-align: sub;
		
	}

	& .bold {
		font-weight: 500;
	}

	& .center {
		align-self: center
	}

	& .right {
		align-self: flex-end
	}

	& .indent {
		text-indent: 0.56rem;
		margin-top: 0.2rem;
	}

	& .space {
		display: inline-block;
		width: 0.2rem;
		
	}
	
	& .table {
		border: 0.005rem solid #000;
		border-collapse: collapse;
	}
	& .tableItem {
		border: 0.005rem solid #000;
		width: 3.3rem;
		padding-left: 0.3rem;
	}
	
}