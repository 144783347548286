.nav {
  padding: 0.3rem 0rem;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 99;
  padding-top: 0.48rem;
  height: 1.48rem;
  &.isIphone{
     height: 1.68rem;
     padding-top: 0.72rem;
   }
  & .am-icon-lg {
    width: 0.5rem;
    height: 0.5rem;
  }
  & .title {
    display: inline-block;
    flex: 1;
    text-align: center;
    font-size: 0.36rem;
    color:rgba(30,30,30,1);
    font-weight:400;
  }
  & .right {
    padding: 0 0.32rem;
  }
  & .left {
    padding: 0 0.2rem;
  }
}
