.am-modal-wrap.coupon-wrap{
	& .am-modal-content{
		padding: 0;
	}
 & .am-modal-body{
	 padding: 0;
 }
}

.CouponModalWrap {
	position: relative;
	padding-top: 0.5rem;
	border-top-left-radius: 0.24rem;
	border-top-right-radius: 0.24rem;
	overflow: hidden;
	max-height: 70%;
	min-height: 4rem;
	background-color:#F6F7FB;
}
.closeIconWrap {
	position: absolute;
	top: 0.4rem;
	right: 0.2rem;
	width: 0.6rem;
	height: 0.6rem;
	justify-content: center;
  align-items: center;
}
.overlayTitle {
	font-size: 0.32rem;
	line-height: 0.44rem;
	color: #3C464F;
	padding: 0 0.32rem;
	margin-bottom: 0.3rem;
	text-align: left;
	font-weight: 600;
}
.couponItemWrap {
	display: flex;
	flex-direction: row;
	padding: 0.26rem 0.3rem;
	border-radius: 0.2rem;
	margin-bottom: 0.16rem;
	position: relative;
	-webkit-border-radius: 0.2rem;
	-moz-border-radius: 0.2rem;
	-ms-border-radius: 0.2rem;
	-o-border-radius: 0.2rem;
	background-color: #FFF0DE;
	/*background-image: url('../../../assets/image/coupon/coupon_card_item_bg.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	 */
}
.couponItemLeft {
	flex: 1;
	padding-right: 0.3rem;
	text-align: left;
}
.couponItemRight {
	position: relative;
	width: 2.01rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* border-top-right-radius: 0.1rem;
	border-bottom-left-radius: 0.1rem;
	border-bottom-right-radius: 0.1rem;
	background-image: url('../../../assets/image/coupon/coupon_card_bg.png');
	background-size: 100% auto;
	background-repeat: no-repeat; */
	border-left: 1px dashed #fff;
}
.titleTxt {
	font-size: 0.32rem;
	line-height: 0.46rem;
	font-weight: 700;
	margin-top: 0.07rem;
	margin-bottom: 0.12rem;
	color: #7C380A;
}
.timeTxt {
	font-size: 0.24rem;
	line-height: 0.34rem;
	color: #9A5628;
}
.cicleTop {
	width: 0.2rem;
	height: 0.2rem;
	border-radius: 0.1rem;
	position: absolute;
	top: -0.36rem;
	left: -0.1rem;
	-webkit-border-radius: 0.1rem;
	-moz-border-radius: 0.1rem;
	-ms-border-radius: 0.1rem;
	-o-border-radius: 0.1rem;
	background-color:#F6F7FB;
}
.cicleBottom {
	width: 0.2rem;
	height: 0.2rem;
	border-radius: 0.1rem;
	position: absolute;
	bottom: -0.36rem;
	left: -0.1rem;
	-webkit-border-radius: 0.1rem;
	-moz-border-radius: 0.1rem;
	-ms-border-radius: 0.1rem;
	-o-border-radius: 0.1rem;
	background-color:#F6F7FB;
}
.linearGradient{
	height: 0.34rem;
	line-height: 0.34rem;
	border-radius: 0.08rem;
	width: 0.9rem;
	text-align: center;
	color: #fff;
	font-size: 0.24rem;
	font-weight: 700;
	background: linear-gradient(149deg,#FF8F4B 0%,#FA4B14 100%);
	-webkit-border-radius: 0.08rem;
	-moz-border-radius: 0.08rem;
	-ms-border-radius: 0.08rem;
	-o-border-radius: 0.08rem;
}
.discountInfo {
	color: #FF5742;
	margin-left: 0.12rem;
	font-size: 0.28rem;
}
.selectImgWrap {
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	width: 0.64rem;
	height: 0.64rem;
	border-top-right-radius: 0.16rem;
	justify-content: center;
	align-items: center;
}
.selectImg {
	width: 0.64rem;
	height: 0.64rem;
}
.useBtnWrap {
	display: flex;
	width: 1.48rem;
	height: 0.48rem;
	border-radius: 0.24rem;
	justify-content: center;
	align-items: center;
	margin-top: 0.2rem;
	-webkit-border-radius: 0.24rem;
	-moz-border-radius: 0.24rem;
	-ms-border-radius: 0.24rem;
	-o-border-radius: 0.24rem;
	background: linear-gradient(to bottom,#f3a654 0%,#fe5500 100%);
}
.useBtnTxt {
	color: #fff;
	font-size: 0.28rem;
}