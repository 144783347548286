.rowBetween {
	flex-direction: row;
	justify-content: space-between;
}

.inlineRow {
	display: flex;
	flex-direction: row;
}

.good-summary {
	flex-direction: column;
	padding: 0.32rem 0.32rem 0;

	& .goodInfoWrap {
		background-color: #fff;
		padding-top: 0.4rem 0.32rem 0;

		& .goodNameTxt {
			font-size: 0.44rem;
			color: #1f1f1f;
			margin-top: 0.26rem;
			line-height: 0.56rem;
		}
	}

	& .chooseArea {
		background-color: #fff;
		padding-top: 0.56rem;

		& .safeIcon {
			width: 0.42rem;
			height: 0.42rem;
		}

		& .certLabel {
			width: 1.18rem;
			height: 0.38rem;
			margin-left: 0.21rem;
		}

		& .standardWrap {
			display: flex;
			flex-direction: row;
			margin-bottom: 0.16rem;
			align-items: center;
		}

		& .safeTxt {
			color: #1f1f1f;
			margin-left: 0.12rem;
			line-height: 0.45rem;
		}

		& .certWrap {
			padding: 0.16rem 0.24rem 0.16rem 0;
			border-radius: 0.28rem;
			justify-content: center;
			align-items: center;
		}

		& .certRow {
			padding: 0.1rem 0;
		}

		& .certLogo {
			width: 1.64rem;
			height: 1.56rem;
		}

		& .certTxt {
			color: #666666;
			font-size: 0.24rem;
			width: 1.12rem;
			text-align: center;
			margin-top: 0.14rem;
			line-height: 0.3rem;
		}

		& .serviceIcon {
			width: 0.32rem;
			height: 0.32rem;
			color: #41d282;
			margin-right: 0.08rem;
		}
	}

	& .infoRow {
		display: flex;
		flex-direction: row;
		padding: 0.3rem 0;
		border-bottom-color: #e6e6e6;
		border-bottom-width: 0.1rem;
		align-items: center;
	}

	& .lastLine {
		border-bottom-width: 0;
	}

	& .infoLabel {
		font-size: 0.32rem;
		color: #999;
		width: 1.74rem;
		line-height: 0.44rem;
		display: inline-block;
	}

	& .infoValue {
		font-size: 0.28rem;
		line-height: 0.44rem;
		display: inline-block;
	}



	& .priceSignal {
		font-size: 0.3rem;
		color: #ff5a5f;
		margin-top: 0.16rem;
	}

	& .priceTxt {
		font-size: 0.6rem;
		color: #ff5a5f;
		margin-left: 0.3rem;
		margin-top: 0.8rem;
	}

	& .priceFloatTxt {
		font-size: 0.5rem;
		color: #ff5a5f;
	}

	& .priceDelete {
		font-size: 0.28rem;
		color: #acacac;
		text-decoration-line: line-through;
		margin-left: 0.32rem;
		margin-top: 0.2rem;
	}

	& .pageSection {
		background-color: #fff;
		padding: 0.4rem 0;
		margin-top: 0.2rem;
	}

	& .pageTitle {
		font-size: 0.32rem;
		color: #1f1f1f;
	}

	& .summaryTxt {
		font-size: 0.28rem;
		color: #666666;
		line-height: 0.48rem;
		margin-top: 0.2rem;
	}

	& .feeLabel {
		margin-left: 0.32rem;
	}

	& .serviceItemWrap {
		padding-right: 0.2rem;
		justify-content: center;
		align-items: center;
	}

	& .arrowRightIcon {
		font-size: 0.28rem;
		color: #cacace;
	}
}