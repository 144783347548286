/*
*footer
*/
.footer-wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
	padding:  0.22rem 0;
  & .btnOpen {
    width: 4.46rem;
    height: 0.88rem;
    background: #3874F5;
    border-radius: 0.44rem;
    text-align: center;
    line-height: 0.88rem;
    font-size: 0.32rem;
    color: #ffffff;
  }
}
