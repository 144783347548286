.centerWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
}
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.am-toast {
  font-size: 0.32rem;
}
.fontLg {
  font-size: 0.48rem;
  color: #1f1f1f;
}
.fontXs {
  font-size: 0.26rem;
  color: #666;
}
/* 收货人列表 */
.addr-list {
  margin-top: 1.12rem;
  & .btmBtn {
    border: 0.02rem solid #fe5500;
    border-radius: 0.44rem;
    text-align: center;
    padding: 0.22rem 0;
    width: 6.7rem;
    position: absolute;
    bottom: 0.8rem;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
  }
}
/* 编辑收货人-start- */
.edit-receiver {
  & .receiver-body {
    padding: 0 0.32rem;
    margin-top: 1.12rem;
  }
  & .edit-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  & .head-save {
    font-size: 0.28rem;
    line-height: 0.38rem;
  }
  & .edit-txt {
    font-size: 0.3rem;
    line-height: 0.48rem;
    width: 100%;
    height: 1.13rem;
    border-bottom: 1px solid #e6e6e6;
  }
  & .edit-area span {
    color: #999;
  }
  & .edit-addr {
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.25rem;
  }
  & .edit-default span {
    color: #1e1e1e;
  }
  & .edit-switch {
    width: 1rem;
    height: 0.6rem;
  }
}

.delete-confirm {
  & .am-modal-transparent {
    width: 5.3rem;
  }
  & .am-modal-content {
    padding-top: 0.5rem;
    border-radius: 0.15rem;
    & .am-modal-body {
      padding: 0;
    }
  }

  & .confirm-title {
    font-size: 0.32rem;
    color: #1f1f1f;
  }
  & .btn-wrap {
    display: flex;

    margin-top: 0.5rem;
    border-top: #cfcfcf 1px solid;
    & div {
      width: 50%;
      height: 1rem;
      font-size: 0.32rem;
      color: #41d282;
    }
    & .confirmBtn {
      border-right: #cfcfcf 1px solid;
      color: #999;
    }
  }
}
/* 编辑收货人-end- */

/* OrderConfirm -start- */
.order-confirm {
	background: #F6F7FB;

	& .addr-wrap {
		width: 0.48rem;
		height: 0.48rem;
		background: #FF9A23;
		border-radius: 0.3rem;
		margin-right: 0.22rem;
		align-items: center;
    justify-content: center;
    display: flex;
		& .addr-img {
			width: 0.34rem;
			height: 0.37rem;
		}
	}

  & .am-activity-indicator-tip {
    font-size: 0.28rem;
    padding: 0.2rem;
    line-height: 0.44rem;
    border-radius: 0.1rem;
    color: #fff;
    opacity: 1;
  }
  & .am-activity-indicator-spinner {
    width: 0.3rem;
    height: 0.3rem;
  }
  & .order-loc {
    width: 0.4rem;
    height: 0.4rem;
  }
  & .body {
    margin-top: 1.48rem;
    font-size: 0;
  }
  & .hideBtmBorder {
		border-bottom-width: 0;
		margin: 0 0.2rem;
		border-radius: 0.08rem;
  }
  & .addReceiverTxt {
    display: inline-block;
    font-size: 0.36rem;
    color: #999;
    line-height: 0.5rem;
  }
  & .right-arrow {
    padding: 0 0.2rem;
    & .am-icon-lg {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  & .wechat-pay-logo {
    width: 0.44rem;
    height: 0.4rem;
  }
  & .pay-wrap {
    font-size: 0.3rem;
    color: #1f1f1f;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem 0.2rem;
    justify-content: space-between;
    z-index: 1;
		margin-top: 0.2rem;
		margin-bottom: 1.7rem;
		background: #fff;

    & .pay-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    & span {
      margin-left: 0.24rem;
    }
    & .pay-check {
      background-color: #fff;
      border: 2px solid #cfcfcf;
      border-radius: 0.36rem;
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  & .btm-note {
    background-color: rgba(243, 199, 38, 0.3);
    color: #ff7e7e;
    font-size: 0.24rem;
    padding: 0.2rem 0.32rem;
    position: fixed;
    bottom: 1rem;
    width: 100%;
  }
  & .btm-bar {
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 1rem;
    font-size: 0.28rem;
    color: #666666;
    z-index: 99;
    & .btm-left {
      padding: 0 0.32rem;
    }
    & .btm-center {
      & .price-int {
        font-size: 0.4rem;
        color: #FE5500;
      }
      & .price-decimal {
        font-size: 0.24rem;
        color: #FE5500;
      }
    }
    & .btm-right {
      background-color: #FF9624;
      padding: 0.16rem 0; 
      width: 2.4rem;
			color: #fff;
			border-radius: 0.4rem;
			font-weight: 500;
			margin-right: 0.2rem;
    }
  }
  & .pay-checked {
    background-color: #41d282;
  }
  & .check-wrap {
    width: 0.44rem;
    height: 0.44rem;
  }
}

/* OrderConfirm -end- */

/* InvoicePage -start-*/
.invoice-page {
  & .head-save {
    font-size: 0.32rem;
  }
  & .top-wrap {
    margin-top: 1.12rem;
    padding: 0.34rem 0.32rem 0.4rem;
  }
  & .btm-wrap {
    padding: 0 0.32rem;
  }
  & .type-value-warp {
    display: flex;
    margin-top: 0.47rem;
  }
  & .invoice-type {
    font-size: 0.32rem;
    color: #666666;
  }
  & .line {
    width: 100%;
    height: 0.2rem;
    background-color: #f6f8fa;
  }
  & .type-desc {
    margin-top: 0.48rem;
    font-size: 0.28rem;
    color: #999;
    line-height: 0.42rem;
  }
}
/* InvoicePage -end-*/

/* PayResult -start- */
/* 出单动画 */

.pay-result {
  & .pay-wrap {
    text-align: center;
    width: 100%;
    z-index: 20;
  }
  & .bg-top {
    width: 100%;
    height: 3.88rem;
    background: url(../../assets/image/mall/bg_top.png) no-repeat center;
    background-size: 100% 3.88rem;
  }
  & .success-wrap {
    position: absolute;
    top: 1.7rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    & .result-txt {
    }
  }
  & .pay-success {
    width: 1.32rem;
  }
  & .result-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0.28rem;
    & .fontLg {
      color: #fff;
      text-align: left;
    }
    & .fontXs {
      color: #fff;
      text-align: left;
      margin-top: 0.1rem;
    }
  }
  & .receipt-top {
    font-size: 0;
    z-index: 30;
    position: absolute;
    width: 100%;
  }
  & .receipt-btm {
    z-index: 10;
    position: absolute;
    top: 3.88rem;
    width: 100%;
    background: url(../../assets/image/mall/bg_btm.png) no-repeat center;
    background-size: 100% 1.24rem;
    height: 1.24rem;
    & .bg-btm {
      width: 7.5rem;
      height: 1.24rem;
    }
  }
  & .paper-bg {
    background-color: #fff;
    text-align: center;
    background: url(../../assets/image/mall/receipt_bg.png) no-repeat center;
    background-size: 6.2rem 4.2rem;
    width: 6.4rem;
    height: 4.2rem;
    position: absolute;
    z-index: 20;
    left: 50%;
    margin-left: -3.2rem;
  }
  & .paper-top {
    text-align: center;
    font-size: 0.48rem;
    color: #fe5500;
    font-weight: 500;
    margin: 0.6rem auto 0.38rem;
    vertical-align: bottom;
    & .paper-top-s {
      font-size: 0.32rem;
    }
  }
  & .separater-line {
    width: 5.84rem;
  }
  & .paper-btm {
    text-align: left;
    margin: 0.4rem 0.6rem;
    & .fontXs {
      margin-bottom: 0.3rem;
    }
  }
  & .shadow {
    position: absolute;
    top: 3.8rem;
    z-index: 21;
    left: 50%;
    width: 6.6rem;
    margin-left: -3.3rem;
  }
  & .check-order {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.receipt-enter {
  transition: all 1000ms ease-in;
  transform: translateY(0%);
}
.receipt-enter-active {
  transform: translateY(86%);
  transition: all 1000ms ease-in;
}
.receipt-enter-done {
  transform: translateY(86%);
  transition: all 1000ms ease-in;
}

/* PayResult -end- */
