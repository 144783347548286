.YYYSDownloadApp{
  overflow-y: hidden;
  height: 100vh;
  background-color: #4D81F0;
  width:100%;
  & .bg-img{
    display: inline-block;
    width: 100%;
  }

  & .cont{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    & .img-txt{
      margin-top: 0.49rem;
      width: 100%;

    }

    & .img-person{
      margin-top: 0.23rem;
      margin-bottom: 0.85rem;
      width: 100%;

    }

    & .img-btn{
      width: 100%;

    }

  }


}