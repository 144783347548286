.insu-wrap {
	padding: 0.3rem 0.3rem 0.5rem;


	& .nav-padding {
		height: 1.5rem
	}

	& .fee-tab {
		flex: 1;
		height:1rem;
		line-height:1rem;
		text-align: center;
		color: #3C464F;
		font-size: 0.28rem;
		background-color: #E7F1FE;
		border-radius: 0.2rem 0.2rem 0 0
	}

	& .fee-tab-selected {
		color: #fff;
		background-color: #3874F5;
	}

	& .section-title {
		margin-top: 0.2rem;
		font-size: 0.34rem;
		color: '#333';
		line-height: 0.48rem;
	}
	& .section-title-mount {
		margin-left: 0.1rem;
	}
	& .section-list {
		padding-top: 0.19rem;
		padding-bottom: 0.1rem;
		font-size: 0.28rem;
		color:'#333'
	}
	& .section-list-item {
		line-height: 0.48rem;
		margin-bottom: 0.24rem

	}


	& .ahead-title {
		font-size: 0.34rem;
		color: #333;
		font-weight: 500;
		margin-top: 0.2rem;
	}

	& .ahead-section {
		font-size: 0.3rem;
		color: #000;
		font-weight: 500;
		line-height: 0.42rem;
		margin-top: 0.2rem;
		margin-bottom: 0.06rem;
		
	}


	& .gap-s {
		margin-top: 0.3rem;
	}
	& .gap-m {
		margin-top: 0.5rem;
	}

	& .indent {
		text-indent: 0.56rem;
		margin-top: 0.2rem;
	}

	& .ahead-brife {
		font-size: 0.28rem;
		color:'#000';
		font-weight: 500;
		line-height: 0.48rem;
		text-align: justify;
		margin-top: 0.14rem;
	}
	& .ahead-extra {
		font-size: 0.28rem;
		color:'#333';
		line-height: 0.48rem;
		text-align: justify;
	}

	& .ahead-subpoints {
		font-size: 0.28rem;
		color:'#333';
		line-height: 0.48rem;
		text-indent: 0.2rem;
		text-align: justify;
		margin-top: 0.15rem;
	}
	& .ahead-append {
		font-size: 0.28rem;
		color:'#333';
		line-height: 0.48rem;
		text-align: justify;
		margin-top: 0.15rem;
	}

	& .licence-li {
		height: 1.4rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom-style: solid;
		border-bottom-width: 0.01rem;
		border-bottom-color: #F1F0F5;
	}

	& .licence-li-head {
		font-size: 0.28rem;
		color: #000;
		flex: 1;
		line-height: 0.4rem;
		margin-right: 1.6rem;
	}

}