.insufee-wrap {
	padding: 0  0.3rem  0.5rem;

	& .nav-padding {
		height: 1.5rem
	}

	& .fee-tab {
		flex: 1;
		height:1rem;
		line-height:1rem;
		text-align: center;
		color: #3C464F;
		font-size: 0.28rem;
		background-color: #E7F1FE;
		border-radius: 0.2rem 0.2rem 0 0
	}

	& .fee-tab-selected {
		color: #fff;
		background-color: #3874F5;
	}

	& .fee-head {
		flex:1;
		height: 1.1rem;
		line-height: 1.1rem;
		text-align: center;
		font-size: 0.28rem;
		font-weight: 500;
		color: #000;
	}
	& .fee-head-m {
		flex:1;
		display: flex;
		flex-direction: column;
		height: 1.1rem;
		align-items: center;
		justify-content: center;
		font-size: 0.28rem;
		font-weight: 500;
		color: #000;
	}
	& .fee-head-sub {
		font-size: 0.2rem
	}
	& .fee-row {
		flex:1;
		height: 0.86rem;
		line-height: 0.86rem;
		text-align: center;
		font-size: 0.26rem;
		color: #333;
	}

	& .fee-row-l {
		background-color: #F6F7F8
	}

	& .career-title {
		font-size: 0.34rem;
		color: #333;
		font-weight: 500;
		margin: 0.3rem 0.2rem;
		line-height: 0.42rem;
	}

	& .career-row {
		flex:1;
		min-height:0.86rem; 
		line-height: 0.86rem;
		text-align: center;
		font-size: 0.26rem;
		color: #333;
	}
	& .career-row-detail {
		flex:2;
		min-height:0.86rem; 
		line-height: 0.56rem;
		text-align: justify;
		font-size: 0.26rem;
		color: #333;
		padding-left: 0.3rem;
		padding-right: 0.3rem;
	}
}