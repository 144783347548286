/* GoodCarousel */
.good-carousel {
  & .video-play {
    width: 7.5rem;
    height: 7.5rem;
  }
  & .am-carousel {
    height: 7.5rem;
  }
  & .img-wrap {
    width: 100%;
    height: 7.5rem;
    /* verticalAlign: 'top' */
  }
  & .slider-list {
    /* height: 7.5rem !important; */
  }
}
